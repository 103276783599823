<template>
	<div>
		<!-- include匹配页面组件的name值 -->
		<keep-alive :include="pageNames">
			<router-view />
		</keep-alive>
	</div>
</template>
<script>
export default {
	data() {
		return {};
	},
	computed: {
		pageNames() {
			return this.$store.state.keepAlivePages;
		},
	},
};
</script>
// 该组件用于有子路由的组件
